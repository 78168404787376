import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const api = axios.create({
  baseURL: 'http://localhost:5000',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

const VerifyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState({ loading: true, error: null });

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        setStatus({ loading: false, error: 'Verification token is missing.' });
        return;
      }

      try {
        const response = await api.get('/api/verify', {
          params: { token },
          // This ensures axios treats 400 responses as resolved promises rather than rejections
          validateStatus: (status) => true
        });

        if (response.status === 200 && response.data?.email) {
          navigate(`/set-password?email=${response.data.email}`);
        } else {
          // Handle non-200 responses without throwing errors
          const errorMessage = response.data?.error || 'Verification failed';
          setStatus({ loading: false, error: errorMessage });
        }
      } catch (error) {
        // This will only catch network errors now
        setStatus({ 
          loading: false, 
          error: 'Unable to connect to verification service. Please try again later.'
        });
      }
    };

    verifyEmail();
  }, [location, navigate]);

  if (status.loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <p>Verifying your email...</p>
      </div>
    );
  }

  if (status.error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <div style={{ color: 'red' }}>{status.error}</div>
      </div>
    );
  }

  return null;
};

export default VerifyPage;
