// src/components/PopularLinks.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { MotionDivider } from './PriceDisplay'; 

// Define the Retro styled typography
const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF', // White lettering
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `, // Thin black outline
  fontWeight: 'bold',
  fontSize: '2.5rem', // Slightly larger title
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive", // Retro font
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));



// Container for the entire Popular Links section
const PopularLinksContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#1a237e',
  padding: '20px',
  borderRadius: '10px',
  width: '45%',
  margin: '0 auto',
  marginTop: '20px',
  marginLeft: '28%',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    width: '94%',          // Increased from 80%
    margin: '10px auto',   // Center alignment
    marginLeft: '3%',      // Minimal left margin
    padding: '8px',        // Reduced padding
  }
}));

// Style for the bullet points container to ensure alignment
const BulletList = styled('ul')({
  paddingLeft: '0px',
  listStyle: 'none',
  margin: 0,
  width: '100%',          // Ensure full width
});

// Custom glowing bullet point styled as a white circle with blue glow
const GlowingBullet = styled(motion('span'))(({ theme }) => ({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  backgroundColor: '#FFFFFF',
  borderRadius: '50%',
  marginRight: '10px',
  marginLeft: '20px',
  boxShadow: `0 0 5px #00BFFF, 0 0 10px #00BFFF, 0 0 15px #00BFFF`,
  verticalAlign: 'middle',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '8px',    // Reduced left margin on mobile
    marginRight: '8px',   // Reduced right margin on mobile
    width: '6px',         // Slightly smaller bullet
    height: '6px',
  }
}));

// Style for each link text
const LinkText = styled(motion('a'))(({ theme }) => ({
  color: '#00BFFF',
  textDecoration: 'none',
  fontSize: '1rem',
  display: 'inline-block',
  marginBottom: '15px',
  marginLeft: '10px',
  wordBreak: 'break-word',
  maxWidth: '80%',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    marginLeft: '4px',    // Reduced left margin
    maxWidth: '85%',      // Slightly wider on mobile
    marginBottom: '12px', // Reduced bottom margin
  }
}));

const PopularLinks = () => {
  const [links, setLinks] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get('/api/popular-links');
        setLinks(response.data);
      } catch (error) {
        console.error('Error fetching popular links:', error);
      }
    };

    fetchLinks();
  }, []);

  const handleClick = (e, index) => {
    e.preventDefault();
    setClickedIndex(index);
    
    // Reset the clicked index after animation completes
    setTimeout(() => {
      setClickedIndex(null);
    }, 800);
  };

  const handleDoubleClick = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  };

  if (links.length === 0) {
    return <Typography sx={{ color: '#FFFFFF' }}>Loading...</Typography>;
  }

  return (
    <>
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <RetroText>Popular Links</RetroText>
        <MotionDivider />
      </Box>
      <PopularLinksContainer>
        <BulletList>
          {links.slice(0, 10).map((link, index) => (
            <li key={link._id} style={{ display: 'flex', alignItems: 'center' }}>
              <GlowingBullet
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ 
                  opacity: 1, 
                  scale: clickedIndex === index ? [1, 1.2, 1] : 1
                }}
                transition={{ 
                  duration: 0.5, 
                  delay: index * 0.1,
                  times: clickedIndex === index ? [0, 0.5, 1] : undefined
                }}
              />
              <LinkText
                href={link.URL}
                onClick={(e) => handleClick(e, index)}
                onDoubleClick={(e) => handleDoubleClick(e, link.URL)}
                animate={clickedIndex === index ? {
                  rotateY: [0, 180, 360],
                  scale: [1, 1.1, 1],
                  color: ['#00BFFF', '#FFD700', '#00BFFF']
                } : {}}
                transition={{
                  duration: 0.8,
                  ease: "easeInOut",
                  times: [0, 0.5, 1]
                }}
                style={{
                  transformStyle: 'preserve-3d',
                  perspective: '1000px',
                  transformOrigin: 'center'
                }}
              >
                {link.URL}
              </LinkText>
            </li>
          ))}
        </BulletList>
      </PopularLinksContainer>
    </>
  );
};

export default PopularLinks;
