// src/components/EducationLinks.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Container for the entire component
const EducationLinksContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#1a237e',
  padding: '20px',
  borderRadius: '16px',
  margin: '20px auto',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '20px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
  backgroundImage: 'linear-gradient(to bottom, #283593, #1a237e)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

// Individual section container
const SectionContainer = styled(Box)({
  backgroundColor: '#283593',
  padding: '15px',
  borderRadius: '12px',
  color: '#FFFFFF',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  backgroundImage: 'linear-gradient(to bottom, rgba(40, 53, 147, 0.9), rgba(26, 35, 126, 0.9))',
});

// Link container
const LinkContainer = styled(Box)(({ theme }) => ({
  marginBottom: '10px',
  '& a': {
    color: '#80DFFF',
    textDecoration: 'none',
    fontWeight: 'bold',
    display: 'block',
    marginBottom: '5px',
    textShadow: '0px 1px 3px rgba(0, 0, 0, 0.6)',
  },
  '& p': {
    margin: 0,
    fontSize: '0.9rem',
    color: '#D1C4E9',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
  },
  [theme.breakpoints.down('sm')]: {
    '& a': {
      fontSize: '0.9rem',
    },
    '& p': {
      fontSize: '0.8rem',
    },
  },
}));

const EducationLinks = () => {
  const sections = [
    {
      title: 'Wallets & Exchanges',
      links: [
        { name: 'Gemini Exchange', url: 'www.gemini.com', description: 'Leading Exchange & Crypto Instituion in US' },
        { name: 'Wallet 2', url: '#', description: 'Description for Wallet 2.' },
        { name: 'Wallet 3', url: '#', description: 'Description for Wallet 3.' },
        { name: 'Wallet 4', url: '#', description: 'Description for Wallet 4.' },
        { name: 'Wallet 5', url: '#', description: 'Description for Wallet 5.' },
      ],
    },
    {
      title: 'Popular Apps',
      links: [
        { name: 'Uniswap', url: 'https://www.uniswap.org', description: 'Home of decentralized finance & beyond.' },
        { name: 'App 2', url: '#', description: 'Description for App 2.' },
        { name: 'App 3', url: '#', description: 'Description for App 3.' },
        { name: 'App 4', url: '#', description: 'Description for App 4.' },
        { name: 'App 5', url: '#', description: 'Description for App 5.' },
      ],
    },
    {
      title: 'Learning',
      links: [
        { name: 'Resource 1', url: '#', description: 'Description for Resource 1.' },
        { name: 'Resource 2', url: '#', description: 'Description for Resource 2.' },
        { name: 'Resource 3', url: '#', description: 'Description for Resource 3.' },
        { name: 'Resource 4', url: '#', description: 'Description for Resource 4.' },
        { name: 'Resource 5', url: '#', description: 'Description for Resource 5.' },
      ],
    },
  ];

  return (
    <EducationLinksContainer>
      {sections.map((section, index) => (
        <SectionContainer key={index}>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontWeight: 'bold',
              fontSize: '1.3rem',
              marginBottom: '12px',
              textAlign: 'center',
              textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            {section.title}
          </Typography>
          {section.links.map((link, idx) => (
            <LinkContainer key={idx}>
              <a href={link.url}>{link.name}</a>
              <p>{link.description}</p>
            </LinkContainer>
          ))}
        </SectionContainer>
      ))}
    </EducationLinksContainer>
  );
};

export default EducationLinks;
