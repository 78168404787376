import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Collapse } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF',
  textShadow: `-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000`,
  fontWeight: 'bold',
  fontSize: '2.5rem',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive",
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const LoanBox = styled(motion(Box))(({ theme }) => ({
  // Base styles
  backgroundColor: '#1a237e',
  padding: '20px',
  borderRadius: '16px',
  marginBottom: '20px',
  color: '#FFFFFF',
  width: '45%',
  margin: '0 auto',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',

  // Improved gradient background
  background: `
    linear-gradient(135deg, 
      rgba(26, 35, 126, 0.95) 0%,
      rgba(21, 101, 192, 0.9) 100%
    )
  `,

  // Texture overlay
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      repeating-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.03) 0px,
        rgba(255, 255, 255, 0.03) 1px,
        transparent 1px,
        transparent 2px
      )
    `,
    pointerEvents: 'none',
  },

  // Shine effect
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.05), transparent)',
    transform: 'translateX(-100%)',
    transition: 'transform 0.5s ease',
  },

  // Box shadow
  boxShadow: '0 4px 15px rgba(0,0,0,0.2), inset 0 0 30px rgba(26,35,126,0.2)',

  // Hover effects
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0,0,0,0.3), inset 0 0 35px rgba(26,35,126,0.3)',
    '&:after': {
      transform: 'translateX(100%)',
      transition: 'transform 1s ease',
    }
  },

  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    width: '94%',                // Slightly reduced from 98% to account for device edges
    margin: '0 auto',            // Center the box
    marginBottom: '10px',        // Keep bottom margin
    padding: '8px',              // Minimal padding
    borderRadius: '8px',         // Smaller radius
    boxShadow: '0 2px 6px rgba(0,0,0,0.15)',
    transform: 'none',           // Remove any transform that might affect centering
    left: '0',                   // Ensure no left offset
    right: '0',                  // Ensure no right offset
  }
}));
 
const RetroLink = styled('a')({
  color: '#00BFFF',
  textDecoration: 'none',
  fontSize: '1.1rem',
  display: 'block',
  margin: '10px 0',
  wordBreak: 'break-word',
  '&:hover': {
    textDecoration: 'underline',
    color: '#80DFFF',
  },
});

const HexGrid = styled(motion.div)(({ theme }) => ({
  // Base styles for all screen sizes
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '20px',
  marginTop: '24px',
  width: '100%',
  position: 'relative',

  // Gradient overlay
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(45deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)',
    pointerEvents: 'none',
  },

  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)', // Keep 2 columns
    gap: '8px',                           // Reduced gap for mobile
    marginTop: '12px',                    // Less top margin
    padding: '0 4px',                     // Add slight padding on sides
  }
}));

// Also update the DataCell component since it's closely related
const DataCell = styled(motion.div)(({ theme }) => ({
  // Base styles
  padding: '20px',
  background: 'linear-gradient(145deg, #2a2a3a 0%, #1a1a2a 100%)',
  borderRadius: '12px',
  border: '1px solid rgba(255,255,255,0.07)',
  transition: 'all 0.4s ease',
  position: 'relative',
  overflow: 'hidden',
  backdropFilter: 'blur(4px)',
  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',

  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 20px rgba(0,0,0,0.3)',
    border: '1px solid rgba(255,255,255,0.12)',
  },

  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    padding: '10px',                    // Reduced padding
    borderRadius: '8px',                // Smaller border radius
    boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
    
    '&:hover': {
      transform: 'translateY(-2px)',    // Smaller hover lift
      boxShadow: '0 4px 12px rgba(0,0,0,0.25)',
    }
  }
}));

// Update the label styling
const DataLabel = styled(Typography)(({ theme }) => ({    // Added theme parameter
  fontSize: '0.9rem',
  color: '#FFE873',
  marginBottom: '8px',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  fontWeight: '500',
  textShadow: '0 0 8px rgba(255,216,0,0.3)',
  '&:hover': {
    textShadow: '0 0 12px rgba(255,216,0,0.4)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',
    letterSpacing: '1px',
    marginBottom: '4px',
    fontWeight: '400'
  }
}));

const DataValue = styled(Typography)(({ theme }) => ({    // Added theme parameter
  fontSize: '1.2rem',
  color: '#FFFFFF',
  fontWeight: '500',
  letterSpacing: '0.5px',
  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    letterSpacing: '0.3px',
    fontWeight: '400',
    lineHeight: '1.2'
  }
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  // Desktop styles
  width: '100%',
  height: 'auto',         // Changed from fixed height
  maxHeight: '400px',     // Maximum height on desktop
  borderRadius: '16px',
  overflow: 'hidden',
  marginBottom: '32px',
  boxShadow: '0 12px 40px rgba(0,0,0,0.3)',
  position: 'relative',
  
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%)',
    pointerEvents: 'none',
  },

  // Mobile styles
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'auto',
    maxHeight: '200px',    // Constrain height on mobile
    marginBottom: '8px',   // Reduced margin
    borderRadius: '4px',   // Minimal border radius
    boxShadow: 'none',    // Remove shadow on mobile
  }
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',   // Changed from 'cover' to 'contain'
  objectPosition: 'center',
  transition: 'transform 0.6s ease',
  
  [theme.breakpoints.down('sm')]: {
    objectFit: 'contain',
    width: '100%',
    height: 'auto',
    maxHeight: '200px'    // Match container max-height
  }
}));

const TopCryptoLoans = () => {
  const [loans, setLoans] = useState([]);
  const [expandedId, setExpandedId] = useState(null);

  useEffect(() => {
    const fetchLoans = async () => {
      try {
        const response = await axios.get('/api/top-loan-companies');
        setLoans(response.data);
      } catch (error) {
        console.error('Error fetching loans:', error);
      }
    };
    fetchLoans();
  }, []);

  const handleCardClick = (id) => {
    setExpandedId(expandedId === id ? null : id); // Toggle card expansion
  
    // Scroll the clicked card into view
    const element = document.getElementById(`loan-card-${id}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300); // Allow time for the animation to start
    }
  };

  if (loans.length === 0) {
    return <Typography sx={{ color: '#FFFFFF' }}>Loading...</Typography>;
  }

  return (
    <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
      <RetroText>2024's Top 10 Crypto Loans</RetroText>
      {loans.map((loan, index) => (
        <LoanBox
          key={loan._id}
          id={`loan-card-${loan._id}`} 
          onClick={() => handleCardClick(loan._id)}
          initial={{ opacity: 0, x: index % 2 === 0 ? '-100vw' : '100vw', rotateY: -90 }}
          animate={{ opacity: 1, x: 0, rotateY: 0 }}
          transition={{ duration: 1, delay: index * 0.3, type: 'spring' }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            #{index + 1} {loan.Name}
          </Typography>
          {loan.URL && (
            <RetroLink href={loan.URL} target="_blank" rel="noopener noreferrer">
              {loan.URL}
            </RetroLink>
          )}
          <Typography variant="body1" sx={{ marginTop: '10px', fontSize: '1.1rem', opacity: 0.9 }}>
            {loan.Description}
          </Typography>

          <Collapse in={expandedId === loan._id} timeout="auto">
  <Box sx={{ 
    mt: { xs: 1, sm: 3 },           // Minimal top margin on mobile
    p: { xs: 0.5, sm: 3 },          // Very minimal padding on mobile
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: { xs: '4px', sm: '16px' }  // Smaller radius on mobile
  }}>
              {loan.ImageURL && (
                <ImageContainer
                  as={motion.div}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <StyledImage
                    src={loan.ImageURL}
                    alt={`${loan.Name} platform`}
                    onError={(e) => {
                      e.target.src = '/api/placeholder/400/200';
                      e.target.alt = 'Platform image not available';
                    }}
                  />
                </ImageContainer>
              )}

              <HexGrid
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                  <DataLabel>Social</DataLabel>
                  <DataValue>{loan.xTwitter}</DataValue>
                </DataCell>
                
                <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                  <DataLabel>Location</DataLabel>
                  <DataValue>{loan.Location}</DataValue>
                </DataCell>
                
                <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                  <DataLabel>Founded</DataLabel>
                  <DataValue>{loan.Founded}</DataValue>
                </DataCell>
                
                <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                  <DataLabel>Team Size</DataLabel>
                  <DataValue>{loan.EmployeeCount}</DataValue>
                </DataCell>
                
                <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                  <DataLabel>Token</DataLabel>
                  <DataValue>{loan.Token}</DataValue>
                </DataCell>
                
                <DataCell whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }}>
                  <DataLabel>Protocol</DataLabel>
                  <DataValue>{loan.Protocol}</DataValue>
                </DataCell>
              </HexGrid>
            </Box>
          </Collapse>
        </LoanBox>
      ))}
    </Box>
  );
};

export default TopCryptoLoans;
