// src/components/PriceDisplay.js

import React, { useState, useEffect, useRef } from 'react';
import { Box, Snackbar, Typography, IconButton, TextField, Button, Link, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import axios from 'axios';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import TopCryptoLoans from './TopCryptoLoans';
import PopularLinks from './PopularLinks';
import PaymentPopup from './PaymentPopup';
import AuthPopup from './AuthPopup';
import UsdVsBtcComparison from './UsdVsBtcComparison';
import UsdToBtcConverter from './UsdToBtcConverter';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import EducationLinks from './EducationLinks';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';




// Retro button with ! and ? signs styled as a white box with shaded blue interior
const RetroSquareButton = styled('div')(({ theme }) => ({
  // Base styles (tablet)
  width: '60px',
  height: '60px',
  backgroundColor: '#1a237e',
  border: '4px solid #FFFFFF',
  color: '#FFFFFF',
  fontSize: '2rem',
  fontFamily: "'Press Start 2P', cursive",
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#283593',
  },
  position: 'fixed',
  zIndex: 100000,

  // Mobile styles (55% smaller)
  [theme.breakpoints.down('sm')]: {
    width: '27px',  // 60px * 0.45 (45% of original size)
    height: '27px', // 60px * 0.45
    fontSize: '0.9rem', // Reduced font size for mobile
    border: '2px solid #FFFFFF', // Thinner border for mobile
  },

  // Desktop/widescreen styles (100% bigger)
  [theme.breakpoints.up('lg')]: {
    width: '120px',  // 60px * 2
    height: '120px', // 60px * 2
    fontSize: '4rem', // Larger font size for desktop
    border: '6px solid #FFFFFF', // Thicker border for desktop
  }
}));

const ModernButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Roboto', sans-serif",  // Modern font to match header
  color: '#FFFFFF',
  backgroundColor: 'transparent',
  border: '2px solid #FFFFFF',
  borderRadius: '4px',
  padding: '2px 12px',
  fontSize: '19px',  // Match the size of other header items
  textTransform: 'none',
  minWidth: 'auto',
  marginBottom: '2px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }
}));

const RetroButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  backgroundColor: 'transparent',
  border: '2px solid #FFFFFF',
  borderRadius: '4px',
  padding: '4px 12px',
  fontSize: '0.7rem',
  textTransform: 'none',
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    boxShadow: '0 0 10px #00BFFF'
  },
  transition: 'all 0.3s ease'
}));


const PaymentButton = ({ onClick }) => {
  const payAudioRef = useRef(null);
  
  const playPaySound = (e) => {
    e.preventDefault();
    if (payAudioRef.current) {
      payAudioRef.current.currentTime = 0;
      payAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playPaySound(e);
    onClick(); // This triggers the popup
  };

  return (
    <>
      <audio ref={payAudioRef} src="/PayButton.mp3" />
      <div onClick={playPaySound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>$</RetroSquareButton>
      </div>
    </>
  );
};

// Buttons with specific content and links
const LeftButton = () => {
  const wheelAudioRef = useRef(null);
  
  const playWheelSound = (e) => {
    e.preventDefault(); // Prevent single click from navigating
    if (wheelAudioRef.current) {
      wheelAudioRef.current.currentTime = 0;
      wheelAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playWheelSound(e);
    window.open('https://wheel.satoshiadvance.com', '_blank');
  };

  return (
    <Box sx={{ position: 'fixed', zIndex: 100000 }}>
      <audio ref={wheelAudioRef} src="/WheelButton.mp3" />
      <div onClick={playWheelSound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ top: '40px', left: '20px', position: 'fixed' }}>!</RetroSquareButton>
      </div>
    </Box>
  );
};



const RightButton = () => {
  const tanksAudioRef = useRef(null);
  
  const playTanksSound = (e) => {
    e.preventDefault();
    if (tanksAudioRef.current) {
      tanksAudioRef.current.currentTime = 0;
      tanksAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playTanksSound(e);
    window.open('https://tanks.satoshiadvance.com', '_blank');
  };

  return (
    <Box sx={{ position: 'fixed', zIndex: 100000 }}>
      <audio ref={tanksAudioRef} src="/TanksButton.mp3" />
      <div onClick={playTanksSound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ top: '40px', right: '20px', position: 'fixed' }}>?</RetroSquareButton>
      </div>
    </Box>
  );
};

// Add this code below your existing LeftButton and RightButton components

const BottomLeftButton = () => {
  const flipAudioRef = useRef(null);
  
  const playFlipSound = (e) => {
    e.preventDefault();
    if (flipAudioRef.current) {
      flipAudioRef.current.currentTime = 0;
      flipAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playFlipSound(e);
    window.open('https://flip.satoshiadvance.com', '_blank');
  };


  return (
    <Box sx={{ position: 'fixed', zIndex: 100000 }}>
      <audio ref={flipAudioRef} src="/FlipButton.mp3" />
      <div onClick={playFlipSound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ bottom: '20px', left: '20px', position: 'fixed' }}>B</RetroSquareButton>
      </div>
    </Box>
  );
};

const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF', // White lettering
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `, // Thin black outline
  fontWeight: 'bold',
  fontSize: '2.4rem', // Adjust size as needed
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive", // Retro font
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem', // Smaller font on mobile
  },
}));

const RetroIconButton = styled(motion(IconButton))(({ theme }) => ({
  color: '#FFFFFF',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  fontSize: '4rem',
  marginRight: '20px',
  '& .MuiSvgIcon-root': {
    fontSize: '4rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem',
    },
  },
}));

export const DividerBar = styled(motion('div'))(({ theme }) => ({
  width: '80%', // Default width for larger screens
  height: '4px', // Thickness of the bar
  backgroundColor: '#FFFFFF',
  margin: '20px auto 0',
  marginBottom: '30px',
  transformOrigin: 'center', // Ensure it grows from the center point
  // Light blue and white glowing effect
  boxShadow: `
    0 0 10px #FFFFFF,
    0 0 20px #00BFFF, /* Light blue glow */
    0 0 30px #00BFFF,
    0 0 40px #00BFFF,
    0 0 70px #FFFFFF, /* White glow */
    0 0 80px #FFFFFF,
    0 0 100px #00BFFF,
    0 0 150px #00BFFF
  `,
  [theme.breakpoints.down('sm')]: {
    width: '95%', // Increase the width to nearly full screen width for smaller devices
  },
}));

export const MotionDivider = () => {
  return (
    <DividerBar
      initial={{ scaleX: 0 }} // Start as a point in the middle
      animate={{
        scaleX: 1, // Grow to full width
        rotateX: 15, // Tilt back at a 15-degree angle
      }}
      transition={{
        duration: 2, // Grow over 2 seconds
        ease: "easeInOut", // Smooth start and stop
      }}
    />
  );
};

const EmailInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '1rem',
    color: '#FFFFFF',
    backgroundColor: '#283593',
    borderRadius: '4px',
    width: '562.5px',
    height: '40px',
    boxSizing: 'border-box',
    padding: '10px 14px',
    border: '2px solid #FFFFFF',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#3949ab',
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused': {
      borderColor: '#FFFFFF',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
    padding: '10px 0',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '95vw',     // Almost full viewport width on mobile
    '& .MuiOutlinedInput-root': {
      width: '100%',      // Full width of container on mobile
    }
  }
}));

const SignUpButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '1rem',
  color: '#FFFFFF',
  backgroundColor: '#283593',
  border: '2px solid #FFFFFF',
  borderRadius: '4px',
  padding: '10px 20px',
  marginLeft: '0',  // Remove the left margin
  textTransform: 'uppercase',
  height: '40px',
  '&:hover': {
    backgroundColor: '#1a237e',
    borderColor: '#FFFFFF',
  },
  [theme.breakpoints.down('sm')]: {
    width: '120px',  // Set fixed width for mobile
    fontSize: '0.8rem',
    padding: '8px 16px',
    margin: '0 auto',  // Center the button
  }
}));

const HeaderContainer = styled(Box)(({ theme }) => ({  // Add theme parameter
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '80%',
  borderBottom: '1px solid #fff',
  height: '30px',
  position: 'fixed',
  top: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  backgroundColor: '#283593',
  paddingTop: '5px',
  padding: '5px 10px',
  [theme.breakpoints.down('sm')]: {
    width: '90%'
  }
}));

const HeaderTitle = styled(Typography)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '22px',
  color: '#fff',
  marginBottom: '2px',
});

const HeaderLink = styled(Link)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#fff',
  textDecoration: 'none',
  marginBottom: '2px',
  cursor: 'pointer', // Add this line to change cursor to pointer hand on hover
  '&:hover': {
    color: '#ccc',
  },
});

const FooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  borderTop: '1px solid #fff',
  height: '30px',
  position: 'fixed',
  bottom: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: '#283593',
  padding: '5px 10px',
  [theme.breakpoints.down('sm')]: {
    width: '90%'
  }
}));

const FooterLink = styled(Link)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#fff',
  textDecoration: 'none',
  '&:hover': {
    color: '#ccc',
  },
});


const PriceDisplay = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [price, setPrice] = useState(null);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0); // Add this
  const [playlist] = useState([
    '/audio/retrodisco.wav',
    '/audio/retrolightning.wav',
    '/audio/retrotrance.wav',
    '/audio/retrogame8.wav',
    '/audio/UpbeatRetroIntro.wav'
  ]);
  const audioRef = useRef(null);
  const aboutAudioRef = useRef(null);
  const nextButtonAudioRef = useRef(null);  // Add this line
  const [isPlaying, setIsPlaying] = useState(false);

  const [emailValid, setEmailValid] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // For payment popup

  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    loanAmount: '',
    useOfFunds: '',
    country: ''
  });

  const [showWelcome, setShowWelcome] = useState(false);
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem('user');
    return saved ? JSON.parse(saved) : null;
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [loginTab, setLoginTab] = useState('login');
  
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUserData(null);
    handleMenuClose();
    window.location.reload();
  };

  const toggleMusic = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.load();
        audioRef.current.play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.log('Error playing audio:', error);
          });
      }
    }
  };
  
  const skipTrack = () => {
    const nextIndex = currentTrackIndex < playlist.length - 1 ? currentTrackIndex + 1 : 0;
    setCurrentTrackIndex(nextIndex);
  };
  
  // Updated track ending handler
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = playlist[currentTrackIndex];
      if (isPlaying) {
        audioRef.current.load();
        audioRef.current.play()
          .catch(error => console.log('Error playing audio:', error));
      }
    }
  }, [currentTrackIndex, playlist, isPlaying]);

  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        console.log('Fetching BTC price...');
        const response = await axios.get('/api/btc-price');
        console.log('BTC price response:', response.data);
        const formattedPrice = parseFloat(response.data.price).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        });
        setPrice(formattedPrice);
      } catch (error) {
        console.error('Error fetching BTC price:', error);
        setPrice('N/A');
      }
    };
  
    fetchBTCPrice();


    const interval = setInterval(fetchBTCPrice, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Attempt to autoplay the music when the component mounts
    if (audioRef.current) {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
      }).catch((error) => {
        console.log('Autoplay failed:', error);
        // Autoplay was prevented; user interaction is required
      });
    }
  }, []);

  useEffect(() => {
    const shouldShowWelcome = localStorage.getItem('showWelcomeNotification');
    if (shouldShowWelcome === 'true') {
      setShowWelcome(true);
      localStorage.removeItem('showWelcomeNotification');
      
      // Play welcome sound
      const audio = new Audio('/audio/retroshot.wav');
      audio.play().catch(error => console.log('Error playing sound:', error));
    }
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Play sound first
    if (nextButtonAudioRef.current) {
      nextButtonAudioRef.current.currentTime = 0;
      nextButtonAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }

    if (formStep === 1) {
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        setEmailValid(false);
        return;
      }
      setEmailValid(true);
      setFormStep(2);
    } else {
      try {
        await axios.post('/api/signup', formData);
        alert('Thank you for signing up!');
        setFormStep(1);
        setFormData({
          email: '',
          loanAmount: '',
          useOfFunds: '',
          country: ''
        });
      } catch (error) {
        console.error('Error signing up:', error);
        alert('Failed to sign up. Please try again.');
      }
    }
  };

  const openPaymentPopup = () => {
    setIsPopupOpen(true);
  };

  const closePaymentPopup = () => {
    setIsPopupOpen(false);
  };

  return (
       <Box
        sx={{
          textAlign: 'center',
          backgroundColor: '#283593',
          padding: '45px 20px 50px 20px',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflowX: 'hidden',
          maxWidth: '100vw',
        }}
      >
        <HeaderContainer>
  <HeaderLink>Sats</HeaderLink>
  <HeaderTitle>Satoshi Advance</HeaderTitle>
  {userData ? (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            backgroundColor: '#283593',
            border: '1px solid #FFFFFF',
            '& .MuiMenuItem-root': {
              fontFamily: "'Roboto', sans-serif",
              fontSize: '19px',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }
          }
        }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <HeaderLink 
        onClick={handleMenuClick}
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '4px'
        }}
      >
        {userData.email}
        <ArrowDropDownIcon />
      </HeaderLink>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <ModernButton 
        onClick={() => {
          setIsSignUpOpen(true);
          setLoginTab('login');
        }}
      >
        Login
      </ModernButton>
      <ModernButton 
        onClick={() => {
          setIsSignUpOpen(true);
          setLoginTab('signup');
        }}
      >
        Sign Up
      </ModernButton>
    </Box>
  )}
</HeaderContainer>

{/* Add this right after HeaderContainer */}
{showWelcome && (
  <Snackbar
    open={true}
    autoHideDuration={6000}
    onClose={() => setShowWelcome(false)}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    <Box
      sx={{
        backgroundColor: '#1a237e',
        color: '#FFFFFF',
        padding: '16px',
        borderRadius: '4px',
        border: '2px solid #FFFFFF',
        fontFamily: "'Press Start 2P', cursive",
        fontSize: '0.8rem',
        boxShadow: `
          0 0 10px #FFFFFF,
          0 0 20px #00BFFF,
          0 0 30px #00BFFF
        `,
      }}
    >
      Account setup complete! Welcome to Satoshi Advance
    </Box>
  </Snackbar>
)}
  
        <LeftButton />
        <RightButton />
        <BottomLeftButton />
        
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '80px',
          }}
        >
          <RetroIconButton
            onClick={toggleMusic}
            initial={{ opacity: 0, x: -50, rotate: 0, y: 0 }}
            animate={{ opacity: 1, x: 0, rotate: [0, 360, 0], y: [0, -10, 0] }}
            transition={{
              opacity: { duration: 1 },
              x: { duration: 1 },
              rotate: {
                duration: 1.5,
                ease: 'linear',
                times: [0, 0.9, 1],
                repeat: Infinity,
                repeatDelay: 15,
              },
              y: {
                duration: 0.5,
                ease: 'easeInOut',
                times: [0, 0.9, 1],
                repeat: Infinity,
                repeatDelay: 15,
              },
            }}
          >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </RetroIconButton>
          <RetroText
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 1 }}
          >
            {price || '$0.00'}
          </RetroText>
          <RetroIconButton
            onClick={skipTrack}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 1 }}
          >
            <SkipNextIcon />
          </RetroIconButton>
        </Box>
  
        <MotionDivider />
  
        <Box sx={{ 
  width: '100%',
  padding: '40px 0',
  backgroundColor: '#1a237e',
  marginY: '40px',
  borderRadius: '10px',
  boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
}}>
  <Typography 
    sx={{ 
      color: '#FFFFFF',
      fontSize: { xs: '1.2rem', sm: '1.8rem' },
      textShadow: `0 0 10px #FFFFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF`,
      fontFamily: "'Press Start 2P', cursive",
      marginBottom: '30px'
    }}
  >
    Apply for a Loan
  </Typography>

  <Box
    component="form"
    onSubmit={handleSubmit}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: { xs: '0 10px', sm: 0 },
      gap: '20px'
    }}
  >
    {formStep === 1 ? (
      <Box sx={{ 
        width: '100%',
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '15px'
      }}>
        <EmailInput
          placeholder="E-mail"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
          error={!emailValid && formData.email !== ''}
          helperText={!emailValid && formData.email !== '' ? 'Invalid email address' : ''}
        />
        <SignUpButton 
          type="submit"
          sx={{ 
            marginTop: '0',
            marginLeft: '0',
            width: '100%',
            maxWidth: '200px'
          }}
        >
          Next
        </SignUpButton>
      </Box>
    ) : (
      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px',
          display: 'flex',
          gap: '20px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          padding: '0 20px'
        }}
      >
        <EmailInput
          placeholder="Loan Amount"
          value={formData.loanAmount}
          onChange={(e) => setFormData({ ...formData, loanAmount: e.target.value })}
          required
        />
        <EmailInput
          placeholder="Use of Funds"
          value={formData.useOfFunds}
          onChange={(e) => setFormData({ ...formData, useOfFunds: e.target.value })}
          required
        />
        <EmailInput
          placeholder="Country"
          value={formData.country}
          onChange={(e) => setFormData({ ...formData, country: e.target.value })}
          required
        />
        <SignUpButton 
          type="submit"
          sx={{ 
            width: { xs: '100%', md: 'auto' },
            minWidth: '150px'
          }}
        >
          Submit
        </SignUpButton>
      </Box>
    )}
  </Box>
</Box>
  
        <audio ref={audioRef} src={playlist[currentTrackIndex]} />
        <audio ref={nextButtonAudioRef} src="/audio/retroshot.wav" />  {/* Add this line */}
  
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: { 
              xs: '0',
              sm: '0',
              md: '-0.42in'
            },
            width: '100%',
            '& > *': {
              width: { xs: '95%', md: '100vw' },
              maxWidth: '2900px',
            },
          }}
        >
        <TopCryptoLoans />
        </Box>
  
        <UsdVsBtcComparison />
        <PopularLinks />
        <UsdToBtcConverter />
        <EducationLinks />

        <PaymentButton onClick={openPaymentPopup}>$</PaymentButton>
        {isPopupOpen && <PaymentPopup onClose={closePaymentPopup} />}
        <FooterContainer>
          <audio ref={aboutAudioRef} src="/audio/retroshot.wav" />
          <FooterLink
            component="div"
            onClick={(e) => {
              e.preventDefault();
              if (aboutAudioRef.current) {
                aboutAudioRef.current.currentTime = 0;
                aboutAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
              }
            }}
            onDoubleClick={() => window.location.href = '/about'}
            sx={{ cursor: 'pointer' }}
          >
            About
          </FooterLink>
        </FooterContainer>
        {isSignUpOpen && <AuthPopup onClose={() => setIsSignUpOpen(false)} />}
      </Box>
  );
};

export default PriceDisplay;
