import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { motion, useInView } from 'framer-motion';

// Styled Box for USD vs BTC Value Display with Animated Border
const AnimatedGlowBox = styled(motion(Box))(({ theme }) => ({
  maxWidth: '600px', // Set max width to limit the width of the box
  padding: '30px', // Extra padding for a more centered appearance
  margin: '20px auto', // Center the box horizontally and add some vertical margin
  backgroundColor: '#283593', // Box background color
  borderRadius: '10px',
  textAlign: 'center',
  position: 'relative', // Position for pseudo-element
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: '20px', // Smaller padding on mobile
    maxWidth: '90%', // Adjust width for mobile devices
  },
}));

// Retro styled Typography for Title
const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '2rem',
  marginBottom: '10px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '1rem', // Smaller size for mobile
  },
}));

// Retro styled Typography for Retro B
const RetroB = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '1.8rem',
  marginLeft: '5px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '0.8rem', // Reduced size for better alignment on mobile
  },
}));

// Satoshi Value Styling
const SatoshiValueText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '1.4rem',
  marginTop: '20px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '0.8rem', // Adjusted for mobile screens
  },
}));

// Satoshi Label Styling (now matches the title size)
const SatoshiLabel = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '1.8rem',
  marginTop: '10px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '1rem', // Reduced size for mobile consistency
  },
}));

const UsdVsBtcComparison = () => {
  const [btcValue, setBtcValue] = useState(null);
  const [satoshiValue, setSatoshiValue] = useState(null);
  const boxRef = useRef(null);
  const isInView = useInView(boxRef, { once: true, margin: '-100px' });

  useEffect(() => {
    const fetchBTCValue = async () => {
      try {
        const response = await axios.get('/api/btc-price'); // Use the existing endpoint
        const btcPrice = response.data.price;
        const usdAmount = 100;
        const btcEquivalent = (usdAmount / btcPrice).toFixed(6);
        setBtcValue(btcEquivalent);

        // Calculate and format satoshi value with commas
        const satoshiEquivalent = Math.round(btcEquivalent * 100000000);
        const formattedSatoshiValue = satoshiEquivalent.toLocaleString();
        setSatoshiValue(formattedSatoshiValue);
      } catch (error) {
        console.error('Error fetching BTC value:', error);
        setBtcValue('N/A');
        setSatoshiValue('N/A');
      }
    };

    fetchBTCValue();
  }, []);

  return (
    <AnimatedGlowBox
      ref={boxRef}
      initial={{ opacity: 0 }}
      animate={isInView ? { opacity: 1, boxShadow: '0 0 20px #9932CC, 0 0 30px #9932CC, 0 0 40px #9932CC' } : {}}
      whileHover={{
        boxShadow: '0 0 40px #BA55D3, 0 0 60px #BA55D3, 0 0 80px #BA55D3', // Brighter glow when hovered
      }}
      whileTap={{
        boxShadow: '0 0 50px #9400D3, 0 0 70px #9400D3, 0 0 90px #9400D3', // Even more intense glow when clicked
      }}
      transition={{
        duration: 1.5,
        ease: 'easeInOut',
      }}
    >
      <TitleText>
        Current value of $100 USD in BTC
      </TitleText>
      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Typography
          sx={{
            fontFamily: "'Press Start 2P', cursive",
            color: '#FFFFFF',
            marginRight: '5px',
            fontSize: '1.8rem',
            textShadow: `
              -1px -1px 0 #000,
              1px -1px 0 #000,
              -1px 1px 0 #000,
              1px 1px 0 #000
            `,
            '@media (max-width: 600px)': {
              fontSize: '0.8rem',
            },
          }}
        >
          $100.0 vs.
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Press Start 2P', cursive",
            color: '#FFFFFF',
            fontSize: '1.8rem',
            textShadow: `
              -1px -1px 0 #000,
              1px -1px 0 #000,
              -1px 1px 0 #000,
              1px 1px 0 #000
            `,
            '@media (max-width: 600px)': {
              fontSize: '0.8rem',
            },
          }}
        >
          {btcValue || 'Loading...'}
        </Typography>
        <RetroB>
          B
        </RetroB>
      </Box>
      {satoshiValue && (
        <>
          <SatoshiValueText>
            {satoshiValue} Sats
          </SatoshiValueText>
          <SatoshiLabel>
            Sats Value
          </SatoshiLabel>
        </>
      )}
    </AnimatedGlowBox>
  );
};

export default UsdVsBtcComparison;
