import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { QRCodeCanvas } from 'qrcode.react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios';


// Styled Components for the Popup
const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Changed from fixed 400px to 90% for mobile
  maxWidth: '400px', // Added maxWidth to maintain desktop size
  padding: '20px',
  backgroundColor: '#1a237e',
  borderRadius: '10px',
  textAlign: 'center',
  zIndex: 1000,
  boxShadow: '0px 0px 20px 0px #00BFFF',
  maxHeight: '90vh', // Added max height for mobile
  overflowY: 'auto', // Added scroll for overflow content
  '@media (max-width: 600px)': {
    padding: '15px', // Smaller padding on mobile
  }
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 999,
}));

const RetroTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1.8rem',
  textShadow: `0 0 10px #FFFFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF`,
  fontFamily: "'Press Start 2P', cursive",
  marginBottom: '20px',
  '@media (max-width: 600px)': {
    fontSize: '1.4rem', // Smaller font size on mobile
  }
}));

const SummaryText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1rem',
  marginBottom: '15px',
  '@media (max-width: 600px)': {
    fontSize: '0.9rem', // Slightly smaller font on mobile
  }
}));

const CopyField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#283593',
  borderRadius: '5px',
  marginBottom: '15px',
  input: {
    color: '#FFFFFF',
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '0.9rem', // Slightly smaller for better mobile fit
    '@media (max-width: 600px)': {
      fontSize: '0.8rem',
    }
  },
}));

const FlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: '-10px',
  marginBottom: '10px',
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '0.9rem',
  marginTop: '-10px',
  marginBottom: '10px',
  '@media (max-width: 600px)': {
    fontSize: '0.8rem',
  }
}));

const PaymentPopup = ({ onClose }) => {
  const [cryptoAmount, setCryptoAmount] = useState(0.00015);  // rename from btcAmount
  const [usdAmount, setUsdAmount] = useState('$10.00');
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState('BTC');
  const [email, setEmail] = useState('');
const cryptoAddresses = {
    BTC: '3KybzUMnSGkJMNmoshXD7wwaeLuENQNpNB', // Your existing BTC address
    ETH: '0xB8ea0cEF886Fa141B3c60E220320FA911d3bBC99',  // Replace with your ETH address
    DOGE: 'DGRAizMZUuQuqMLSTUt7AxoEsktYryA6s1' // Replace with your DOGE address
};

useEffect(() => {
  const fetchCryptoPrices = async () => {
    try {
      const response = await axios.get('/api/crypto-payment-prices');
      const prices = response.data.prices;
      const targetUsdAmount = 15; // $15 USD target

      // Calculate crypto amounts for $15 USD
      const cryptoEquivalents = {
        BTC: (targetUsdAmount / prices.BTC).toFixed(8),
        ETH: (targetUsdAmount / prices.ETH).toFixed(8),
        DOGE: (targetUsdAmount / prices.DOGE).toFixed(2)
      };

      setCryptoAmount(cryptoEquivalents[selectedCrypto]);
      setUsdAmount(`$${targetUsdAmount.toFixed(2)}`);
      
    } catch (error) {
      console.error('Error fetching crypto prices:', error);
      setUsdAmount('N/A');
    }
  };

  fetchCryptoPrices();
}, [selectedCrypto]); // Run when selected crypto changes

useEffect(() => {
  const updateUsdAmount = async () => {
    try {
      const response = await axios.get('/api/crypto-payment-prices');
      const prices = response.data.prices;
      
      // Calculate USD based on current crypto amount
      const calculatedUsd = (cryptoAmount * prices[selectedCrypto]).toFixed(2);
      setUsdAmount(`$${calculatedUsd}`);
    } catch (error) {
      console.error('Error updating USD amount:', error);
      setUsdAmount('N/A');
    }
  };

  updateUsdAmount();
}, [cryptoAmount, selectedCrypto]);

  const handleCryptoAmountChange = (event) => {
    setCryptoAmount(event.target.value);
  };

  const handleIncrement = () => {
    setCryptoAmount((prevAmount) => {
      const newAmount = (parseFloat(prevAmount) + 0.00001).toFixed(8);
      return newAmount;
    });
  };

  const handleDecrement = () => {
    setCryptoAmount((prevAmount) => {
      const newAmount = Math.max(parseFloat(prevAmount) - 0.00001, 0).toFixed(8);
      return newAmount;
    });
  };

  const handleConfirmChange = (event) => {
    setConfirmChecked(event.target.checked);
  };

  const handleSendReceipt = async () => {
    if (!confirmChecked) {
      alert('Please confirm the payment before sending the receipt.');
      return;
    }
  
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }
  
    try {
      await axios.post('/api/payment-notification', {
        email,
        cryptoAmount,
        selectedCrypto,
        usdAmount,
      });
  
      alert('Receipt sent! Thank you for your payment.');
    } catch (error) {
      console.error('Error sending payment notification:', error);
      alert('Failed to send receipt. Please try again.');
    }
  };
  
  

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(cryptoAddresses[selectedCrypto]);
    alert('Crypto Address Copied!');
  };

  const handleCryptoSelect = (crypto) => {
    setSelectedCrypto(crypto);
  };
  

  return (
    <>
      <Overlay onClick={onClose} />
      <PopupContainer>
        <RetroTitle>Subscription Upgrade!</RetroTitle>

        <QRCodeCanvas 
  value={cryptoAddresses[selectedCrypto]} 
  size={180} 
  style={{ 
    margin: '20px auto',
    maxWidth: '100%',
    height: 'auto'
  }} 
/>

<CopyField
  value={cryptoAddresses[selectedCrypto]}
  InputProps={{
    readOnly: true,
    endAdornment: (
      <InputAdornment position="end">
        <ContentCopyIcon
          onClick={handleCopyToClipboard}
          sx={{ cursor: 'pointer', color: '#FFFFFF' }}
        />
      </InputAdornment>
    ),
  }}
  fullWidth
/>
<FlexContainer>
<LabelText sx={{ color: '#00BFFF', textAlign: 'left', cursor: 'pointer' }}>
  <Tooltip title="Dogecoin" arrow>
    <span onClick={() => handleCryptoSelect('DOGE')} style={{color: selectedCrypto === 'DOGE' ? '#FFFFFF' : '#00BFFF'}}>DOGE</span>
  </Tooltip> | 
  <Tooltip title="Ethereum" arrow>
    <span onClick={() => handleCryptoSelect('ETH')} style={{color: selectedCrypto === 'ETH' ? '#FFFFFF' : '#00BFFF'}}>ETH</span>
  </Tooltip> | 
  <Tooltip title="Bitcoin" arrow>
    <span onClick={() => handleCryptoSelect('BTC')} style={{color: selectedCrypto === 'BTC' ? '#FFFFFF' : '#00BFFF'}}>BTC</span>
  </Tooltip>
</LabelText>
  <LabelText onClick={handleCopyToClipboard} sx={{ paddingRight: '10px', textAlign: 'right' }}>Click to copy</LabelText>
</FlexContainer>

        <Box 
          display="flex" 
          justifyContent="space-between" 
          alignItems="center" 
          mb={1}
          sx={{
            gap: '8px',
            '@media (max-width: 600px)': {
              flexDirection: 'row',
              alignItems: 'center'
            }
          }}
        >
          <IconButton
            onClick={handleDecrement}
            sx={{ 
              color: '#FFFFFF', 
              backgroundColor: '#283593', 
              borderRadius: '5px',
              padding: '8px'
            }}
          >
            <RemoveIcon />
          </IconButton>
          <CopyField
            value={cryptoAmount}
            onChange={handleCryptoAmountChange}
            fullWidth
            sx={{ textAlign: 'center' }}
          />
          <IconButton
            onClick={handleIncrement}
            sx={{ 
              color: '#FFFFFF', 
              backgroundColor: '#283593', 
              borderRadius: '5px',
              padding: '8px'
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>


<FlexContainer>
  <LabelText>{selectedCrypto} Amount</LabelText>
  <LabelText>{usdAmount} USD</LabelText>
</FlexContainer>

        <FormControlLabel
  control={
    <Checkbox 
      checked={confirmChecked} 
      onChange={handleConfirmChange} 
      sx={{ 
        color: '#00BFFF',
        '& .MuiSvgIcon-root': {
          fontSize: '1.2rem'
        }
      }} 
    />
  }
  label={<Typography sx={{ color: '#FFFFFF', fontSize: '0.9rem' }}>Confirm Payment</Typography>}
/>

<TextField
  label="Confirm Email"
  variant="outlined"
  value={email}
  onChange={(e) => setEmail(e.target.value)}
  fullWidth
  sx={{
    backgroundColor: '#283593',
    borderRadius: '5px',
    marginTop: '15px',
    marginBottom: '15px',
    input: {
      color: '#FFFFFF',
      fontFamily: "'Press Start 2P', cursive",
    },
    label: {
      color: '#FFFFFF',
    },
  }}
/>


<SummaryText>
  You confirm this 1 time payment, <span style={{ textDecoration: 'underline' }}>with no auto pay enabled or available</span>, for 1 year annual subscription to SatoshiAdvance.com in exchange for {cryptoAmount} {selectedCrypto}, approximately {usdAmount} USD as of 10.24.2024.
</SummaryText>

<Button
  onClick={handleSendReceipt}
          sx={{
            backgroundColor: '#00BFFF',
            color: '#FFFFFF',
            fontFamily: "'Press Start 2P', cursive",
            textTransform: 'none',
            '&:hover': { backgroundColor: '#00BFFF' },
            marginTop: '15px',
            padding: '10px',
            fontSize: {
              xs: '0.8rem',
              sm: '1rem'
            }
          }}
          fullWidth
        >
          Upgrade & Email Receipt
        </Button>
      </PopupContainer>
    </>
  );
};

export default PaymentPopup;
